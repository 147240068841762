<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10081')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<Loading v-if="booking === undefined" />
	<div id="container" class="container container--fit" v-else>
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">{{t('10083')}}</h2>
						<div class="booking-top__date">
							<div class="flex">
							<span>{{booking.BOOKING_DATETIME}}</span>
								<span>
									<a @click="handleOpenMessage" class="btn-link">{{t('10502')}}</a>
								</span>
							</div>
						</div>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="booking-total">
								<h3 class="booking-total__title">
									{{t('10084')}}
								</h3>
								<div class="booking-total__desc">
									{{t('10085')}}
									<span>{{booking.BOOKING_NO}}</span>
								</div>
							</div>
							<!-- 예약 숙소 1 -->
							<!-- [D] 비활성화시 booking-item--disabeld 클래스 추가 -->
							<div class="booking-item booking-item--disabeld">
								<div class="booking-item__date">
									{{t('10086')}} <span>{{booking.BOOKING_NO}}</span>
								</div>
								<div class="booking-item__badge">숙소</div>
								<h3 class="booking-item__title">{{booking.HOTEL_NM}}</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">{{booking.PROD_TYPE}}</span>
									{{booking.PROD_NM}}
								</p>
								<div class="booking-item__date">
									{{booking.checkin}} ~ {{booking.checkout}}<br>
									{{booking.VISIT_MEANS}}
								</div>
								<div class="booking-item__price">
									<strong>{{booking.SALES_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}
								</div>
								<div class="booking-item__select" v-if="booking.CANCEL_AVAIL_DATE.length !== 0 && availableCancel">
									<span class="description red">
										{{booking.CANCEL_AVAIL_DATE}}{{t('10089')}}
									</span>
								</div>
							</div>
						</div>
					</article>
					<!-- 예약자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10090')}} </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{booking.ISSUE_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{booking.ISSUE_HP}}
										</div>
									</li>
								</ul>
							</div>
							<!-- 방문자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10453')}} </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{booking.GUEST_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{booking.GUEST_HP}}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</article>
					<!-- 금액 및 결제 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">{{t('10095')}}</h3>
							<ul class="info">
								<li>
									<h4 class="info__title">
										{{t('10096')}}
									</h4>
									<div class="info__text">
										{{booking.PAY_TYPE}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10097')}}
									</h4>
									<div class="info__text">
										{{booking.SALES_AMT.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10098')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_CP ? "-" : ""}}{{booking.DC_AMT_CP.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10099')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_PT ? "-" : ""}}{{booking.DC_AMT_PT.toLocaleString("en-US")}}P
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10100')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_OT ? "-" : ""}}{{booking.DC_AMT_OT.toLocaleString("en-US")}}{{t('10088')}}
									</div>
								</li>
								<li class="point">
									<h4 class="info__title">{{t('10101')}} </h4>
									<div class="info__wrap">
										<div class="info__text"><strong class="red">{{booking.PAID_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}</div>
									</div>
								</li>
							</ul>
						</div>
					</article>
					<!-- 취소 및 환불 정보
					<article class="booking-box booking-box--last">
						<div class="box box--reverse">
							<h3 class="booking-box__title">취소 및 환불 정보</h3>
							<h4 class="booking-box__subtitle">
								취소일시 : 21.11.02(목) 14:50
							</h4>
							 취소 및 환불 정보
							<ul class="info">
								<li>
									<h4 class="info__title">
										환불상태
									</h4>
									<div class="info__text">
										환불 완료
									</div>
								</li>
								<li>
									<h4 class="info__title">
										결제금액
									</h4>
									<div class="info__text">
										37,200원
									</div>
								</li>
								<li>
									<h4 class="info__title">
										취소수수료
									</h4>
									<div class="info__text">
										10,000원
									</div>
								</li>
							</ul>
							<div class="booking-box__notice">
								취소수수료는 판매가 (상품가격) 기준으로 산정 됩니다.
							</div>
							 환불 예정 금액 
							<ul class="info info--large">
								<li class="point">
									<h4 class="info__title">환불(예정)금액</h4>
									<div class="info__wrap">
										<div class="info__text"><strong class="red">27,000</strong>원</div>
									</div>
								</li>
								<li>
									<h4 class="info__title">환불 방법</h4>
									<div class="info__text">
										신용카드 환불
									</div>
								</li>
							</ul>
							<div class="booking-box__notice">
								환불 예상 금액은 쿠폰과 제휴사 금액을 포함하지 않습니다.
							</div>
							<div class="booking-line"></div>
							 취소 안내문
							<ul class="notice-list">
								<li>
									예약/결제 당시에 적용된 취소/환불 규정에 따라
									취소 수수료가 계산됩니다.
								</li>
								<li>
									예약/결제 시 사용한 포인트는 포인트로, 쿠폰은 쿠폰으로
									복원됩니다. 단, 취소 수수료를 차감한 금액으로 복원될 수
									있습니다. 포인트나 쿠폰은 취소에 의한 복원 여부에
									상관없이 원 유효기간 등에 따라 잔액이 소멸될 수 있으니
									주의 바랍니다.
								</li>
								<li>
									환불은 예약취소 후 PG사 승인 취소 기간에 따라 주말,
									공휴일을 제외한 영업일 기준 최대 3~5일 소요됩니다.
								</li>
								<li class="red">
									(주)헬로스테이는 통신판매중개자로서, 통신판매의 당사자가
									아니라는 사실을 고지하며, 상품의 예약, 이용 및 환불 등과 관련한 의무와 책임을 각 판매자에게 있습니다.
								</li>
							</ul>
						</div>
					</article> -->
				</section>
			</div>
		</div>
	</div>
	<HistoryDelete v-if="openMessage" :handleClose="handleCloseMessage" :handleSubmit="handleSubmit"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import HistoryDelete from '@/components/layers/Message_historydelete';
import api from "@/urls/mmy02";
import dayjs from 'dayjs';
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");
export default {
	components: {
		HistoryDelete
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const openMessage = ref(false);
		const { t }= useI18n() //번역필수 모듈
		const userData = computed(() => store.state.userData);
		const booking = computed(() => {
			const finded = store.state.mmy02.mmy0202[route.params.id]?.find(item => item.SEQ === Number(route.query.seq));
			if(finded) {
				return {
					...finded,
					BOOKING_DATETIME: dayjs(finded.BOOKING_DATETIME).format("YY.MM.DD(dd) HH:mm"),
					checkin: dayjs(finded.SDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKIN_TIME,
					checkout: finded.SDATE === finded.EDATE ?  finded.CHECKOUT_TIME : dayjs(finded.EDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKOUT_TIME,
				}
			}else {
				return undefined;
			}
		});

		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0202", { proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id });
		})
		return {
			booking,
			openMessage,
			goBack:() => {
				router.back();
			},
			handleOpenMessage: () => {
				openMessage.value = true;
			},
			handleCloseMessage: () => {
				openMessage.value = false;
			},
			handleSubmit: async () => {
				await api.getMmy0203({
					proc_cd: "01",
					mem_id: userData.value.mem_token,  
					booking_no: route.params.id
				})
				openMessage.value = false;
				router.back();
			},
			t,
			i18n
		};
	},
}
</script>